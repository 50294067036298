export default function form() {
	// eslint-disable-next-line no-undef
	const $ = jQuery;

	/* * * * * * * * * * * * * * * * *
	 * *   S H O W * & * H I D E   * *
	 * * * * * * * * * * * * * * * * *

	Die Formulare enthalten viele inaktive Schalter, die Teile des Formulars ausblenden und andere Teile sichtbar machen.

	Dabei verdient das Attribut required besondere Aufmerksamkeit, denn wir müssen verhindern,
	das ein ausgeblendetes Formular-Feld dieses Arttibut hat, weil ansonsten der User keine
	Möglichkeit hätte, das Formular abzuschicken.

	Die foldenden beiden Methoden kümmern sich darum und sollten zum ein- und ausblenden verwendet werden.
	*/

	function showFormPart( $jqSel ) {
		$jqSel.find( 'input[data-required="true"], select[data-required="true"], textarea[data-required="true"]' ).prop( 'required', true );
		$jqSel.show();
	}

	function hideFormPart( $jqSel ) {
		$jqSel.find( 'input[required], select[required], textarea[required]' ).prop( 'required', false ).attr( 'data-required', 'true' );
		$jqSel.hide();
	}

	/* * * * * * * * * * * * * * * *
	 * *   MARK REQUIRED LABELS  * *
	 * * * * * * * * * * * * * * * */

	/* Alle Labels, die zu einem Pflichtfeld gehören, sollten passend gekennzeichnet werden.*/

	$( '.wr-form' ).find( 'input[type=text][required], input[type=email][required], input[type=number][required], input[type=date][required], textarea[required], select[required]' ).each( function( idx, el ) {
		const id = $( el ).attr( 'id' );
		const label = $( el ).closest( '.row' ).find( '.labels label[for=' + id + ']' );
		if ( label.find( '.req' ).length === 0 ) {
			label.append( '<a class="req" title="Pflichtfeld">*</a>' );
		}
	} );

	$( '.wr-form .row.question.req' ).find( 'p.question' ).append( '<a class="req" title="Pflichtangabe">*</a>' );

	/* * * * * * * * * * * * * * * *
	 * *       A D D R E S S     * *
	 * * * * * * * * * * * * * * * */

	// Handle address switch button.

	$( '.wr-form' ).on( 'change', '.address .switch input[type="checkbox"]', function() {
		//console.log( $(this).is( ':checked' ) );
		const addr = $( this ).closest( '.address' );
		const streetRow = addr.find( '.row.street-and-house-number' );
		const zipRow = addr.find( '.row.zip-code-and-location' );
		const countryRow = addr.find( '.row.country' );
		const textareaRow = addr.find( '.row.address-text' );
		if ( $( this ).is( ':checked' ) ) {
			hideFormPart( streetRow );
			hideFormPart( zipRow );
			hideFormPart( countryRow );
			showFormPart( textareaRow );

			const textarea = textareaRow.find( 'textarea[name$="address-text"]' );
			const street = streetRow.find( 'input[name$="street"]' ).val();
			if ( textarea.val() === '' && street !== '' ) {
				const houseNumber = streetRow.find( 'input[name$="house-number"]' ).val();
				const zipCode = zipRow.find( 'input[name$="zip-code"]' ).val();
				const location = zipRow.find( 'input[name$="location"]' ).val();
				textarea.val( `${ street } ${ houseNumber }\n${ zipCode } ${ location }\n` );
			} else {
				// eslint-disable-next-line no-console
				console.log( textareaRow.find( 'textarea[name$="address-text"]' ) );
			}
		} else {
			showFormPart( streetRow );
			showFormPart( zipRow );
			showFormPart( countryRow );
			hideFormPart( textareaRow );
		}
	} );

	// Die Sichbarkeit beim Laden der Seite (kann auch über den Zurück-Button im Browser erfolgen!) aktualisieren:

	$( '.address .switch input[type="checkbox"]' ).each( function( idx, el ) {
		$( el ).trigger( 'change' );
	} );

	/* * * * * * * * * * * * * * * *
	 * *        G R A V E        * *
	 * * * * * * * * * * * * * * * */

	$( '.wr-form' ).on( 'change', '.grave .row.dead-or-alive input[type=radio]', function() {
		const grave = $( this ).closest( '.grave' );
		const addr = grave.find( '.address' );
		const street = addr.find( '.row.street-and-house-number' );
		const zip = addr.find( '.row.zip-code-and-location' );
		const country = addr.find( '.row.country' );
		const addrText = addr.find( '.row.address-text' );
		const addrSwitch = addr.find( '.row.address-switch' );
		const phone = addr.find( '.row.phone' );
		const email = addr.find( '.row.email' );
		const death = grave.find( '.row.death' );

		//var selectedValue = $( '.grave .row.dead-or-alive input[type="radio"]:checked' ).val();
		const selectedValue = grave.find( '.row.dead-or-alive input[type=radio]:checked' ).val();

		/*
		Drei Fälle sind zu unterscheiden:

		Es ist entweder garkeine oder eine der beiden Radioboxen aktiviert:
		 */
		if ( ! selectedValue ) {
			hideFormPart( street );
			hideFormPart( zip );
			hideFormPart( country );
			hideFormPart( addrText );
			hideFormPart( addrSwitch );
			hideFormPart( phone );
			hideFormPart( email );
			hideFormPart( death );
		}

		if ( selectedValue === 'dead' ) {
			//console.log( 'dead' );
			hideFormPart( street );
			hideFormPart( zip );
			hideFormPart( country );
			hideFormPart( addrText );
			hideFormPart( addrSwitch );
			hideFormPart( phone );
			hideFormPart( email );
			showFormPart( death );
		}

		if ( selectedValue === 'alive' ) {
			//console.log( 'alive' );
			showFormPart( street );
			showFormPart( zip );
			showFormPart( country );
			showFormPart( addrSwitch );
			showFormPart( phone );
			showFormPart( email );
			hideFormPart( death );
			onDeathHide( death );
			/* Je nachdem ob der switch ein- oder ausgeschaltet ist, sollten bestimme
			Adress-Felder sichtbar sein oder nicht. Das Event-Handling dafür findet
			sich weiter oben. Wir müssen lediglich ein change-Event feuern, um es zu aktivieren */
			addr.find( '.switch input[type="checkbox"]' ).trigger( 'change' );
		}
	} );

	// Die Sichbarkeit beim Laden (kann auch über den Zurück-Button im Browser erfolgen!) aktualisieren:

	/* * * * * * * * * * * * * * * *
	 * *  D A T E - F I E L D S  * *
	 * * * * * * * * * * * * * * * */

	// date fields
	const now = new Date();
	const today = now.toISOString().substring( 0, 10 );

	$( '.future' ).prop( 'min', today );
	$( '.past' ).prop( 'max', today );

	/* Birth and Death

	Geburtsdatum darf nicht nach dem Todesdatum liegen.
	*/

	$( '#wrf-graves' ).on( 'change', 'section.grave .birth.row input[type=date]', function() {
		//console.log( 'birthdate changed')
		const birth = $( this ).val();
		const death = $( this ).closest( 'section.grave' ).find( '.death.row input[type=date]' );
		death.prop( 'min', birth );
	} );

	$( '#wrf-graves' ).on( 'change', 'section.grave .death.row input[type=date]', function() {
		const death = $( this ).val();
		const birth = $( this ).closest( 'section.grave' ).find( '.birth.row input[type=date]' );
		birth.prop( 'max', death );
	} );

	$( '.wr-form section.deceased' ).on( 'change', '.birth.row input[type=date]', function() {
		//console.log( 'birthdate changed')
		const birth = $( this ).val();
		const death = $( this ).closest( 'section.deceased' ).find( '.death.row input[type=date]' );
		death.prop( 'min', birth );
	} );

	$( '.wr-form section.deceased' ).on( 'change', '.death.row input[type=date]', function() {
		const death = $( this ).val();
		const birth = $( this ).closest( 'section.deceased' ).find( '.birth.row input[type=date]' );
		birth.prop( 'max', death );
	} );

	/**
	 * Wenn wir die death row ausblenden, gibt es einiges zu beachten:
	 *
	 * Sobald hier ein Wert gesetzt wird, wird auch das max-Attribut des zugehörigen
	 * Birth-Feldes auf diesen Wert gesetzt, um zu verhindern, dass das Geburtsdatum nach dem Todesdatum
	 * liegt (siehe unten).
	 *
	 * Wenn das Todesdatum ausgeblendet wird, wäre aber für den User nicht mehr verständlich,
	 * warum er das Geburtsdatum nicht mehr auf einen früheren Termin setzen kann.
	 *
	 * Deshalb setzen wir das max-Attribute des zugehörigen Birth-Feldes zurück auf das heutige Datum.
	 *
	 * Außerdem leeren wir das Todesdatum. Das ist notwendig, weil ansonsten Birth nicht auf einen späteren Wert
	 * gesetzt werden könnte.
	 *
	 * @param {string} deathRow Die Zeile die versteckt wird.
	 */
	function onDeathHide( deathRow ) {
		const death = deathRow.find( 'input[type=date]' );
		const birth = deathRow.closest( 'section.grave' ).find( '.birth.row input[type=date]' );

		death.val( '' );
		birth.prop( 'max', today );
	}

	/* * * * * * * * * * * * * * * *
	 * *   DYNAMIC GRAVE LIST    * *
	 * * * * * * * * * * * * * * * */

	let graveCounter = 0;
	const maxNumberOfGraves = 12;

	const grave = $( '#wrf-grave-template' ).html();
	$( '#wrf-grave-template' ).empty();

	/**
	 * Refreshes the headline numbers, the graveCounter and disables or enables buttons
	 * to prevent that the number of graves never gets lower 1 or higher then the set maxNumberOfGraves.
	 */
	function refresh() {
		// Refresh headline numbers
		const self = $( 'input[name=self]:checked' ).val();
		const counterStart = ( self === 'yes' ? 2 : 1 );

		$( '#wrf-graves section.grave' ).each( function( idx, el ) {
			$( el ).find( 'h3' ).text( 'Ruhestätte ' + ( idx + counterStart ) );
		} );

		// Refresh graveCounter
		graveCounter = $( '#wrf-graves section.grave' ).size() + counterStart - 1;
		$( '#number-of-graves' ).val( graveCounter );

		/* enable or disable buttons, to ensure the graveCounter stays in range*/
		if ( graveCounter === maxNumberOfGraves ) {
			$( 'button#add-grave' ).prop( 'disabled', true );

			if ( $( 'input[name=self]:checked' ).val() !== 'yes' ) {
				$( 'input[name=self][value=yes]' ).prop( 'disabled', true );
			}
		} else {
			$( 'button#add-grave' ).prop( 'disabled', false );
			$( 'input[name=self][value=yes]' ).prop( 'disabled', false );
		}

		/* Unter bestimmten Umständen soll der Button mit der Klasse "close-button" disabled werden, nämlich:

		Wenn der User die Frage, ob es eine Ruhstätte für sich selbst erwerben will, verneint,
		dann muss er minestens eine Grabbox ausfüllen. In diesem Fall wollen wir ihn daran hindern, die letzte Grabbox
		zu entfernen.*/

		if ( $( 'input[name=self]:checked' ).val() === 'no' && graveCounter === 1 ) {
			$( '.close-button' ).prop( 'disabled', true );
		} else {
			$( '.close-button' ).prop( 'disabled', false );
		}
	}

	// * *         A D D         * */
	$( 'button#add-grave' ).click( function() {
		if ( graveCounter < maxNumberOfGraves ) {
			const timestamp = new Date().getTime();
			const tsGrave = grave.replace( /grave-n/g, 'grave-' + timestamp );
			$( '#wrf-graves' ).append( tsGrave );

			const currentGrave = $( '#wrf-graves' ).find( '#grave-' + timestamp );
			currentGrave.find( 'input[name=grave-' + timestamp + '-dead-or-alive]:first-child' ).trigger( 'change' );
			$( '<input>', {
				type: 'hidden',
				name: 'timestamps[]',
				value: timestamp,
			} ).appendTo( currentGrave );
			refresh();
		}
	} );

	// * * R E M O V E  * */
	$( '#wrf-graves' ).on( 'click', 'section.grave .close-button', function() {
		const graveClicked = $( this ).closest( 'section.grave' );
		graveClicked.remove();
		refresh();
	} );

	/* *   G R A V E * S E L F   * */

	$( 'input[type=radio][name=self]' ).change( function() {
		const selfMsg = $( '.row.self-msg' );
		const clientBirth = $( '.row.client-birth' );
		const selectedValue = $( 'input[type=radio][name=self]:checked' ).val();

		if ( ! selectedValue ) {
			hideFormPart( selfMsg );
			hideFormPart( clientBirth );
		}

		if ( selectedValue === 'yes' ) {
			showFormPart( selfMsg );
			showFormPart( clientBirth );
			graveCounter++;
		}

		if ( selectedValue === 'no' ) {
			hideFormPart( selfMsg );
			hideFormPart( clientBirth );

			if ( $( '#wrf-graves section.grave' ).size() === 0 ) {
				$( 'button#add-grave' ).click();
			}
		}

		refresh();
	} );

	/* * * * * * * * * * * * * * * *
	 * *   Bestattungsformular   * *
	 * * * * * * * * * * * * * * * */

	$( 'select#trauerfeier' ).change( function() {
		const val = $( this ).val();
		const toggleArea = $( '.burial .row.waldruh' );
		if ( val === 'waldruh' ) {
			toggleArea.show();
		} else {
			toggleArea.hide();
		}
	} );

	// show and hide cremation area depending of selected value
	$( 'select#urne' ).change( function() {
		const val = $( this ).val();
		const toggleArea = $( '.burial .cremation, .crematorium-contact' );
		if ( val === 'send' ) {
			toggleArea.show();
		} else {
			toggleArea.hide();
		}
	} );

	// show and hide area "tree-and-grave-number" depending of selected value
	$( 'select#place-choice' ).change( function() {
		const val = $( this ).val();
		const toggleArea = $( '.tree-number, .grave-number' );
		if ( val === 'choosen' ) {
			toggleArea.show();
		} else {
			toggleArea.hide();
		}
	} );

	// show and hide categories depending of selected value
	$( 'select#place-choice' ).change( function() {
		const val = $( this ).val();
		const toggleArea = $( '.cat-area' );
		if ( val === 'wr' ) {
			toggleArea.show();
		} else {
			toggleArea.hide();
			$( '.number-of-graves' ).hide();
		}
	} );

	// Show ".number-of-graves" only, if the selected category is not a tree
	$( 'input:radio[name="cat"]' ).change( function() {
		const toggleArea = $( '.cat-grave .number-of-graves' );
		if ( this.checked && $( this ).attr( 'data-cat-type' ) === 'grave' ) {
			toggleArea.show();
		} else {
			toggleArea.hide();
		}
	} );

	// If the selectbox country is set to other, replace the selectbox by a simple text-field
	$( 'select[name$="country"]' ).change( function() {
		const val = $( this ).val();
		//console.log( val );

		if ( val === 'other' ) {
			$( this ).closest( 'label' ).hide();
			$( this ).closest( '.row' ).find( 'label.alternative-text-field' ).show();
		}
	} );

	// If the selectbox is set to the value "other", replace the selectbox by a simple text-field
	$( 'select[name="deceased-religion"]' ).change( function() {
		const val = $( this ).val();
		if ( val === 'other' ) {
			$( '.row.religion' ).hide();
			$( '.row.religion-text' ).show();
		}
	} );

	$( 'select' ).trigger( 'change' );
	$( 'input[type=radio]' ).trigger( 'change' );

	$( 'button#print' ).click( function() {
		//console.log("print");
		window.print();
	} );

	/* * * * * * * * * * * * * * * *
	 * *     BESTELL FORMULAR    * *
	 * * * * * * * * * * * * * * * */

	$( 'input[type=radio][name=tree-select]' ).change( function() {
		const selectedValue = $( 'input[type=radio][name=tree-select]:checked' ).val();
		const treeNumberRow = $( '.row.tree-number' );
		const categoriesRow = $( '.row.cat-area' );

		if ( selectedValue === 'by-customer' ) {
			showFormPart( treeNumberRow );
			hideFormPart( categoriesRow );
		}

		if ( selectedValue === 'by-team' ) {
			showFormPart( categoriesRow );
			hideFormPart( treeNumberRow );
		}
	} );

	$( 'input[type=radio][name=tree-select]' ).trigger( 'change' );
}
