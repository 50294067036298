import { app } from './app.js';

import '../../frontend/css-src/styles.css';

window.addEventListener( 'DOMContentLoaded', app );

// See https://vitejs.dev/guide/api-hmr.html
if ( import.meta.hot ) {
	import.meta.hot.accept( './app.js', ( newApp ) => {
		newApp?.app();
	} );
}
